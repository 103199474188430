//@flow

import React, {useState} from 'react';
import {feedbackEmail, iubendaID, privacyPolicyLink, relativeRoot, termsAndConditionsLink} from "../../common";
import {useUnsubscribe} from "../../effects/unsubscribe";
import {Link, useHistory} from "react-router-dom";
import {useSignIn} from "../../effects/signIn";
import {useTranslation} from "react-i18next";
import {useStartupEffect} from "../../effects/custom";
import useLanguages from "../../effects/languages";
import {useAtom} from "@reatom/react";
import {SubscriberAtom} from "../../atoms/Subscriber";
import {gtagEvent} from "../../utils/gtag";
import {useUnsubExperiment} from "../../contexts/ExperimentsContext";


const Footer = (props) => {
  useStartupEffect();
  const {languages, languageChangeHandler} = useLanguages();
  const unsubscribeHandler = useUnsubscribe();
  const signInHandler = useSignIn();
  const signInPopUp = signInHandler.signInPopUp();
  const {t} = useTranslation();
  const subscriberState = useAtom(SubscriberAtom);
  const history = useHistory();
  const currYear = (new Date()).getFullYear();

  const [signIn, setSignIn] = useState(() => {
  })

  const unsubExperiment = useUnsubExperiment()
  const unsubName = t(`experiment.unsub.${unsubExperiment}`);


  React.useEffect(() => {
    setSignIn(() => {
      return signInHandler.showProfilePopUp
    })
    if (subscriberState.loaded) {
      if (subscriberState.subscriber && subscriberState.subscriber.email) {
        setSignIn(() => {
          return history.push;
        })
      }
    }
  }, [subscriberState.subscriber])

  return (
    <footer className="footer"
            id="footer"
    >
      <div className="container">
        <div className="left-box"
             style={{width: "100%"}}
        >
          <p className="copyright"
          >{t('footer.Copyright')} © {currYear}. {t('footer.All rights received')}.</p>
        </div>
        <div className="left-box"
             style={{width: "100%"}}>
          <ul className="footer-menu">
            <li>
              <Link to={`${relativeRoot}/about-us/`}
              >{t('footer.About Us')}</Link>
            </li>
            <li>
              <Link to={`${relativeRoot}/contact-us/`}
              >{t('footer.Contact Us')}</Link>
            </li>
            <li>
              <Link to={`${relativeRoot}/feedback/`}
              >{t('footer.Feedback')}</Link>
            </li>
            <li>
              <a target={`_blank`}
                 href={`https://www.iubenda.com/privacy-policy/${iubendaID}`}
              >{t('footer.Privacy Policy')}</a>
            </li>
            <li>
              <Link to={`${relativeRoot}/terms-of-service/`}
              >{t('footer.Terms of Service')}</Link>
            </li>
            <li>
              <a onClick={() => {
                signIn('/profile');
                gtagEvent({action: "manage account click", category: "manage account", label: "manage-desktop-footer"});
              }}
                 className="pointer">
                {unsubName ? unsubName : t('footer.Profile')}
              </a>
            </li>
          </ul>
        </div>
        <div className="left-box"
             style={{width: "100%"}}>
          <ul className="footer-menu">
            <li>
              <a title={t('footer.Sign In')}
                 className="pointer"
                 onClick={signInHandler.showSignInPopUp}
              >{t('footer.Sign In')}</a>
              {signInPopUp}
            </li>
            <li>
              <a target={`_blank`}
                 href={`https://www.iubenda.com/privacy-policy/${iubendaID}/cookie-policy`}
              >{t('footer.Cookie Policy')}</a>
            </li>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                }}
                className="iubenda-ccpa-opt-out"
              >{t('footer.Do Not Sell My Personal Information')}</a>
            </li>
          </ul>
        </div>
        <div className="left-box"
             style={{width: "100%"}}>
          <ul className="footer-menu" style={{width:"fit-content", float:'left'}}>
            <li>
              {(languages.map((lang, idx) => {
                return <a key={idx}
                          className={`language pointer`}
                          href={lang.url === 'en' ? `/` : `/${lang.url}/`}
                          onClick={(e) => {
                            e.preventDefault();
                            languageChangeHandler(lang.url)
                          }}>{lang.url.toUpperCase()} {idx !== languages.length - 1 && (
                  <span style={{fontWeight: '100'}}>&nbsp;|&nbsp;</span>)} </a>
              }))}
            </li>
            {/*<li><a href="/my-baby-generator/" className={`landing`}>Baby generator</a></li>*/}
            {/*<li><a href="/see-what-will-my-baby-look-like/" className={`landing`}>What will my baby look like</a></li>*/}
            {/*<li><a href="/see-what-will-our-baby-look-like/" className={`landing`}>What will our baby look like</a></li>*/}
            {/*<li><a href="/what-would-our-baby-look-like/" className={`landing`}>What would our baby look like</a></li>*/}
          </ul>
          <ul className="footer-menu" style={{float: "right", width:"fit-content"}}>
            <li>
              <a href="#" className="iubenda-cs-uspr-link">Notice at Collection</a>
            </li>
            <li>
              <a href='#' className='iubenda-cs-preferences-link'> <img style={{width: '2rem'}}
                                                                        src="data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 14' style='enable-background:new 0 0 30 14;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7d .st1%7bfill-rule:evenodd;clip-rule:evenodd;fill:%230066FF;%7d .st2%7bfill:%23FFFFFF;%7d .st3%7bfill:%230066FF;%7d %3c/style%3e%3cg%3e%3cg id='final---dec.11-2020_1_'%3e%3cg id='_x30_208-our-toggle_2_' transform='translate(-1275.000000, -200.000000)'%3e%3cg id='Final-Copy-2_2_' transform='translate(1275.000000, 200.000000)'%3e%3cpath class='st0' d='M7.4,12.8h6.8l3.1-11.6H7.4C4.2,1.2,1.6,3.8,1.6,7S4.2,12.8,7.4,12.8z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3cg id='final---dec.11-2020'%3e%3cg id='_x30_208-our-toggle' transform='translate(-1275.000000, -200.000000)'%3e%3cg id='Final-Copy-2' transform='translate(1275.000000, 200.000000)'%3e%3cpath class='st1' d='M22.6,0H7.4c-3.9,0-7,3.1-7,7s3.1,7,7,7h15.2c3.9,0,7-3.1,7-7S26.4,0,22.6,0z M1.6,7c0-3.2,2.6-5.8,5.8-5.8 h9.9l-3.1,11.6H7.4C4.2,12.8,1.6,10.2,1.6,7z'/%3e%3cpath id='x' class='st2' d='M24.6,4c0.2,0.2,0.2,0.6,0,0.8l0,0L22.5,7l2.2,2.2c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0 l0,0l-2.2-2.2L19.5,10c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l0,0L20.8,7l-2.2-2.2c-0.2-0.2-0.2-0.6,0-0.8 c0.2-0.2,0.6-0.2,0.8,0l0,0l2.2,2.2L23.8,4C24,3.8,24.4,3.8,24.6,4z'/%3e%3cpath id='y' class='st3' d='M12.7,4.1c0.2,0.2,0.3,0.6,0.1,0.8l0,0L8.6,9.8C8.5,9.9,8.4,10,8.3,10c-0.2,0.1-0.5,0.1-0.7-0.1l0,0 L5.4,7.7c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l0,0L8,8.6l3.8-4.5C12,3.9,12.4,3.9,12.7,4.1z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
                                                                        alt="California Consumer Privacy Act (CCPA) Opt-Out Icon"/>Your
                Privacy Choices</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
export default Footer;